




















































































































































































































































































































































































































































































































































































/* eslint-disable no-case-declarations */

import { fetchForecastDataGlobal, vrsStore } from '@/store'

import { convertDate } from '@/helpers/dates'
import { vrsStoreEvent } from '@/store/event/store'

import Player from '@/components/ui/Player/Player.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import RainInformation from '@/components/data/forecast/rain/RainInformation.vue'
import WeatherStationListing from '@/components/data/weatherstation/WeatherStationListing.vue'
import ServiceInformation from '@/components/data/information/ServiceInformation.vue'
import Button from '@/components/ui/Button/Button.vue'
import Chart from '@/components/data/weatherstation/Chart.vue'
import ForecastMix from '@/components/data/forecast/ForecastMix.vue'
import WidgetSettings from '@/components/ui/WidgetSettings/WidgetSettings.vue'
import DashboardSettings from '@/components/ui/DashboardSettings/DashboardSettings.vue'
import Overview from '@/components/data/weatherstation/Overview/Overview.vue'
import CompareSessions from '@/components/data/weatherstation/CompareSessions/CompareSessions.vue'
import WrapperMapLegend from '@/components/map/WrapperMapLegend/WrapperMapLegend.vue'
import MapLegend from '@/components/map/MapLegend/MapLegend.vue'
import MapSettings from '@/components/map/MapSettings/MapSettings.vue'
import preferences from '@/services/preferences'

import { GridItem, GridLayout } from 'vue-grid-layout'
import { WeatherEvent } from '@/store/event/definitions'
import { fetchRadarBbox } from '@/store/time/store'
import { getDatasets } from '@/components/data/weatherstation/helper'
import { fetchWeatherStationDataFilteredByDate } from '@/store/weatherstation/store'
import { ChartDataset, datasetsConfiguration, VueGridItem } from './definition'
import { filterForecastDataByDate, findLatestForecastValueDateForSpecificType } from '@/store/forecast/helpers'
import { selectionSessions } from '@/views/ClimateRecap/helper'
import { DefaultSettingCompareSession } from '@/components/data/weatherstation/CompareSessions/definitions'
import { typesWidgets } from '@/components/ui/WidgetSettings/helper'
import { setChartDatasetRanges } from '@/views/CustomDashboard/helper'
import { defaultDashboard } from '@/helpers/defaults'
import { DateTime } from 'luxon'
import { vrsStoreInformation } from '@/store/infos/store'

const defaultColNum = 96
const defaultRowHeight = 15

const seriesColor = [
  '#DD66D8',
  '#55C116',
  '#00B3FF',
  '#FFA933'
]

export default {
  name: 'CustomDashboard',
  components: {
    'ui-player': Player,
    'ui-loader': Loader,
    'ui-button': Button,
    'wrapper-map-legend': WrapperMapLegend,
    'map-mapbox': () => import('@/components/map/MapboxGL/MapboxGL.vue'),
    'map-leaflet': () => import('@/components/map/Leaflet/Leaflet.vue'),
    'map-legend': MapLegend,
    'map-settings': MapSettings,
    'forecast-rain-information': RainInformation,
    'weather-station-listing': WeatherStationListing,
    'service-information': ServiceInformation,
    'forecast-mix': ForecastMix,
    'vgl-grid-layout': GridLayout,
    'vgl-grid-item': GridItem,
    'widget-settings': WidgetSettings,
    'dashboard-settings': DashboardSettings,
    'ground-station-chart': Chart,
    'event-overview': Overview,
    'compare-sessions': CompareSessions
  },
  props: {
    section: {
      type: String,
      default: 'forecast'
    },
    dashboardIndex: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      stateApp: vrsStore.state.app,
      stateAuth: vrsStore.state.auth,
      stateConfig: vrsStore.state.config,
      stateForecast: vrsStore.state.forecast,
      stateInformation: vrsStore.state.information,
      stateEvent: vrsStore.state.event,
      stateMessage: vrsStore.state.message,
      stateWeatherStation: vrsStore.state.weatherStation,
      stateGeneral: vrsStore.state,
      stateTime: vrsStore.state.time,
      stateReplaySettings: vrsStore.state.app.data.replaySettings,

      dataPlayerRainForecast: null, // Link to Position in Player if Exist
      dataPlayerInformation: null, // Link to Position in Player if Exist

      currentWidgetInEdition: null,
      dashboardStatus: 'read', // read - update
      displayTimeline: true,

      // vue-grid-layout settings
      colNum: defaultColNum,
      rowHeight: defaultRowHeight,

      frameRate: 4,
      isPlaying: false,
      timeoutId: null,
      animationLengths: [15, 30, 60, 120, 180, 240, 300, 360],
      displayDashboardSettings: false,
      displayMapLegend: false,
      displayMapSettings: false,

      /**
       * Memorize which widgets are minimized,
       * and not synced
       */
      minimizedWidgets: {},
      mapBackground: 'light' as 'light' | 'dark' | 'shadedrelief' | 'satellite'
    }
  },
  computed: {
    // currentTime,
    // selectedRangeLocal,
    // timesToDisplayUTC
    ...vrsStore.modules.time.computed,
    allDataTimeRangesLocal: vrsStore.computed.allDataTimeRangesLocal,
    displayWBGTAndHeatIndex: vrsStore.modules.auth.computed.displayWBGTAndHeatIndex,
    displayLightIntensity: vrsStore.modules.auth.computed.displayLightIntensity,
    defaultSelectedCompareSession (): DefaultSettingCompareSession {
      return {
        ...selectionSessions({
          defaultSessionA: null,
          defaultSessionB: null,
          defaultStationA: 'all',
          defaultStationB: 'all'
        }, this.stateEvent.sessionsWithStat)
      }
    },

    widgetFromDashboard () {
      // Avoid reference issue between currentDashboard and currentWidgetInEdition
      // Allows direct visualisation of changes when editing the widget when creating widget
      if (this.currentDashboard?.layout.length > 0 && this.currentWidgetInEdition) return this.currentDashboard.layout[this.currentWidgetInEdition.i]
      return null
    },

    isPlayerInDashboard (): boolean {
      if (this.displayMapPlayer) return true
      if (this.currentDashboard?.layout.length > 0) {
        return this.currentDashboard?.layout.some(widget => widget.type === 'player')
      }
      return false
    },

    hourlyData () {
      return vrsStore.state.forecast.data.hourly?.data?.slice(0, 1)
    },

    flattenData () {
      if (!this.hourlyData || !vrsStore.state.forecast.data.hourly?.flattenData) return []
      return vrsStore.state.forecast.data.hourly?.flattenData?.slice(0, this.hourlyData[0]?.data?.length || 0) || []
    },

    /**
     * Current dashboard in edition
     * Be careful, for compatibility,
     * we need to add "new" properties here to be saved later.
     */
    currentDashboard () {
      const dashboardFound = vrsStore.state.auth.data.user?.dashboards[this.dashboardIndex]
      if (dashboardFound) {
        dashboardFound.settings = {
          ...defaultDashboard.settings,
          ...dashboardFound.settings
        }
        return dashboardFound
      } else {
        return {
          ...defaultDashboard
        }
      }
    },

    isDashboardEditMode () {
      return this.dashboardStatus === 'update'
    },

    /**
     * Leaflet / Mapbox map settings
     */
    mapComponentToUse (): string {
      return vrsStore.modules.app.computed.mapComponentToUse()
    },

    animationLength () {
      return this.animationLengths.indexOf(vrsStore.state.app.data.animationLength) !== -1 ? vrsStore.state.app.data.animationLength : 0
    },

    imageResolution: vrsStore.modules.app.computed.imageResolution,

    /**
     * Player methods + getters
     */
    timeoutBetweenImages () {
      return 1000 / this.frameRate
    },
    displayMap () {
      return this.currentDashboard.settings.displayMapBackground
    },
    displayMapPlayer () {
      return this.currentDashboard.settings.displayMapPlayer
    },
    displayMapPlayerTop () {
      return this.displayMap && this.currentDashboard.settings.displayMapPlayer && this.currentDashboard.settings.mapPlayerPosition === 'top'
    },
    displayMapPlayerBottom () {
      return this.displayMap && this.currentDashboard.settings.displayMapPlayer && this.currentDashboard.settings.mapPlayerPosition === 'bottom'
    },
    displayGroundStationTrackOrWBGT () {
      return vrsStore.modules.config.state.data.DISPLAY_DASHBOARD_WIDGET_GROUND_STATION_TRACK_WBGT
    }
  },
  mounted () {
    fetchForecastDataGlobal()
    if (this.$route.query.edit === 'true') this.dashboardStatus = 'update'
  },
  beforeRouteUpdate (to, from, next) {
    if (this.$route.query.edit === 'true') this.dashboardStatus = 'update'
    next()
  },
  methods: {
    updateMapBackground (name: 'light' | 'dark' | 'shadedrelief' | 'satellite') {
      this.mapBackground = name
    },
    cannotWidgetDisplaySync (itemType) {
      return [
        'forecast',
        'event-overview',
        'compare-sessions',
        'player',
        'ground-station-chart-multi-station',
        'ground-station-chart',
        'unknown'
      ].includes(itemType)
    },

    changeAnimationLength (newValue: number) {
      if (this.animationLengths.indexOf(vrsStore.state.app.data.animationLength) !== -1) {
        preferences.defaultAnimationLength = newValue
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          length: newValue
        }
      })
    },

    getData (type: string, isLinkToPlayer = false) {
      switch (type) {
        case 'forecast-rain-information':
          if (isLinkToPlayer) {
            return this.dataPlayerRainForecast ? this.dataPlayerRainForecast.rain : this.stateForecast.data.rain
          }
          return this.stateForecast.data.rain
        case 'service-information':
          if (isLinkToPlayer) {
            return this.dataPlayerInformation
          }
          return this.stateInformation.data
        case 'weather-station-listing':
        case 'weather-station-listing-multi-station':
        case 'weather-radar-map':
          if (isLinkToPlayer) {
            return this.stateWeatherStation?.dataByTimestamp?.map(wsd => (wsd[this.currentTime.local]))
          }
          return this.stateWeatherStation.latest
      }
    },

    /**
     * Refresh all data displayed in widgets.
     *
     * This method is called when currentTime is updated,
     * so when the player is running or the user is moving the timeline.
     *
     */
    refreshData () {
      if (!this.currentDashboard || this.currentDashboard.layout.length === 0 || !this.currentTime || !this.stateForecast?.apiData) return

      let hasPlayer = this.displayMapPlayer
      let replayRain = false
      let replayInfo = false

      this.currentDashboard.layout.forEach(widget => {
        if (widget.type === 'player') hasPlayer = true
        if (widget.params.syncedWithPlayer && widget.type === 'forecast-rain-information') replayRain = true
        if (widget.params.syncedWithPlayer && widget.type === 'service-information') replayInfo = true
      })

      if (hasPlayer) {
        if (replayRain) {
          this.dataPlayerRainForecast = {
            ...filterForecastDataByDate(
              this.stateForecast.apiData,
              this.currentTime.luxonDate,
              this.stateEvent.data.timezone,
              this.stateEvent.data.sessions,
              this.stateConfig.data
            )
          }
        }
        if (replayInfo) {
          const infosDataBeforeCurrentTime = this.stateInformation.all.filter(i => i.datestring <= this.currentTime.local)
          if (infosDataBeforeCurrentTime) {
            this.dataPlayerInformation = infosDataBeforeCurrentTime
          } else {
            this.dataPlayerInformation = null
          }
        }
      }
    },

    /**
     * Compute all missed new data for minimized widgets
     * and not "synced" (but realtime)
     * and type of widget implemented
     */
    refreshNotifications () {
      this.currentDashboard.layout.forEach(widget => {
        let notificationValue = null
        if (this.minimizedWidgets[widget.i]) {
          const minimizationDatestring = this.minimizedWidgets[widget.i].since
          switch (widget.type) {
            /**
             * For rain data, we try to know if since > latest rain report
             */
            case 'forecast-rain-information':
              const latestRainValueDateString = findLatestForecastValueDateForSpecificType(this.stateForecast.apiData, 'rain')
              notificationValue = latestRainValueDateString > minimizationDatestring ? '!' : null
              break
            case 'service-information':
              const latestServiceInformationDateString = vrsStoreInformation.actions.findLatestInfoDateString()
              notificationValue = latestServiceInformationDateString > minimizationDatestring ? '!' : null
              break
            /**
             * For forecast data, we need to know if there is already hourly data.
             * If not, we compute from daily data.
             * If yes, we compute from hourly data.
             */
            case 'forecast':
              const latestForecastValueDateString = findLatestForecastValueDateForSpecificType(this.stateForecast.apiData, 'forecast')
              notificationValue = latestForecastValueDateString > minimizationDatestring ? '!' : null
              break
          }
          this.$set(this.minimizedWidgets[widget.i], 'notifications', notificationValue)
        }
      })
    },

    /**
     * Dashboard management methods
     */
    openDashboard () {
      this.dashboardStatus = 'update'
    },

    closeDashboard () {
      this.dashboardStatus = 'read'
    },

    addNewWidget () {
      // Add a new item. It must have a unique key!
      this.currentWidgetInEdition = {
        x: 0, // begin of the line
        y: this.currentDashboard.layout.length + (this.colNum || defaultColNum), // puts it at the bottom
        w: 4,
        h: 4,
        i: this.currentDashboard.layout.length,
        title: 'Unknown widget',
        type: 'unknown',
        params: {},
        styles: { padding: true, border: true, displayTitle: true, opacity: 100, size: 'base' }
      }
      this.currentDashboard.layout.push(this.currentWidgetInEdition)
    },

    removeCurrentWidget () {
      const indexOfCurrentWidget = this.currentDashboard.layout.findIndex(w => w.i === this.currentWidgetInEdition.i)
      if (indexOfCurrentWidget > -1) {
        this.currentDashboard.layout.splice(indexOfCurrentWidget, 1)
      }
      // recompute all `i` variable
      this.currentDashboard.layout.forEach((w, index) => { w.i = index })
      this.currentWidgetInEdition = null
    },

    async saveDashboard () {
      // this.currentDashboard.label = (this.$refs.h1DashboardLabel as HTMLElement).innerText
      const dashboards = [
        ...vrsStore.modules.auth.state.data.user.dashboards
      ]
      dashboards[this.dashboardIndex] = { ...this.currentDashboard }
      await vrsStore.modules.auth.actions.saveDashboards(dashboards)
      this.dashboardStatus = 'read'
      this.displayDashboardSettings = false
    },

    async removeDashboard () {
      const dashboards = [
        ...vrsStore.modules.auth.state.data.user.dashboards
      ]
      dashboards.splice(this.dashboardIndex, 1)
      await vrsStore.modules.auth.actions.saveDashboards(dashboards)
      this.$router.push(vrsStore.modules.auth.computed.defaultHomePage())
    },

    changeSyncedWithPlayer (item): void {
      const currentWidget = this.currentDashboard.layout.find(widget => widget.i === item.i)
      this.$set(currentWidget.params, 'syncedWithPlayer', !currentWidget.params.syncedWithPlayer)
      /**
       * Be careful if widget is minimized, we need to add/remove it to/from the notification system
       */
      if (
        !currentWidget.params.maximized && // widget is minimized
        ['forecast-rain-information', 'service-information', 'forecast'].indexOf(item.type) >= 0
      ) {
        if (this.minimizedWidgets[currentWidget.i] && currentWidget.params.syncedWithPlayer) { // widget was referenced but is now synced => we remove it
          delete this.minimizedWidgets[currentWidget.i]
        } else if (!this.minimizedWidgets[currentWidget.i] && !currentWidget.params.syncedWithPlayer) { // widget was not referenced, is now desync => we add it
          this.$set(this.minimizedWidgets, currentWidget.i, {
            since: DateTime.fromMillis(Date.now(), { setZone: true, zone: vrsStoreEvent.state.activeEvent.timezone })
              .toFormat('yyyyMMddHHmm00'),
            notifications: null
          })
        }
      }
    },

    // Player
    fastBackward () {
      vrsStore.state.time.indexTimesToDisplayUTC = 0
    },

    backward () {
      if (vrsStore.state.time.indexTimesToDisplayUTC > 1) {
        vrsStore.state.time.indexTimesToDisplayUTC--
      }
    },

    forward () {
      if (vrsStore.state.time.indexTimesToDisplayUTC < this.timesToDisplayUTC.length - 1) {
        vrsStore.state.time.indexTimesToDisplayUTC++
      }
    },

    fastForward () {
      vrsStore.state.time.indexTimesToDisplayUTC = this.timesToDisplayUTC.length - 1
    },

    goToIndex (i) {
      if (i > 0 && i < this.timesToDisplayUTC.length) {
        vrsStore.state.time.indexTimesToDisplayUTC = i
      }
    },

    togglePlay () {
      this.isPlaying = !this.isPlaying
      this.isPlaying ? this.play() : this.stop()
    },

    play () {
      vrsStore.state.time.indexTimesToDisplayUTC =
          (vrsStore.state.time.indexTimesToDisplayUTC + 1) % this.timesToDisplayUTC.length
      this.timeoutId = setTimeout(() => {
        this.play()
      }, this.timeoutBetweenImages)
    },

    stop () {
      clearTimeout(this.timeoutId)
    },

    getGroundStationChartTitle (item: VueGridItem) {
      const itemStationDataset: ChartDataset = item.params.stationDataset as ChartDataset
      let title = item.title
      if (!itemStationDataset) return title
      if (itemStationDataset.datasetRainIntensity) {
        const stationIndex: number = item.params.stationIndex as number
        if (
          vrsStore.state.weatherStation.data &&
        stationIndex < vrsStore.state.weatherStation.data.length
        ) {
          const stationData = vrsStore.state.weatherStation.data[stationIndex]
          if (stationData) {
            const datasets = getDatasets(stationData)
            if (datasets) {
              title += ' Total for period : ' + datasets.rainIntensityTotal + 'mm'
            }
          }
        }
      }
      return title
    },

    getStationListingTitle (item, stationName) {
      const data = this.getData(item.type, item.params.syncedWithPlayer)
      const stationIndex = parseInt(stationName.replace('station', ''), 10) - 1
      const stationData = data[stationIndex]
      if (stationData?.timeToDisplay) {
        return `Station ${stationIndex + 1} at ${stationData.timeToDisplay}`
      } else {
        return `Station ${stationIndex + 1} : no data available`
      }
    },

    getLabelStyle (stationName) {
      let stationIndex = 0
      switch (stationName) {
        case 'station1':
          stationIndex = 0
          break
        case 'station2':
          stationIndex = 1
          break
        case 'station3':
          stationIndex = 2
          break
        case 'station4':
          stationIndex = 3
          break
      }
      return `color: ${seriesColor[stationIndex]}`
    },

    /**
     * Chart widget
     */
    getGroundStationChartDataset (item: VueGridItem) {
      const stationIndex: number = item.params.stationIndex as number
      const itemStationDataset: ChartDataset = item.params.stationDataset as ChartDataset || {
        datasetAirTemp: false,
        datasetTrackTemp: false,
        datasetWindAvg: false,
        datasetWindGust: true,
        datasetHumidity: false,
        datasetPressure: false,
        datasetRainIntensity: true,
        datasetWindDirection: false,
        datasetWBGT: false,
        datasetHeatIndex: false,
        datasetLightIntensity: false
      }
      if (
        vrsStore.state.weatherStation.data &&
        stationIndex < vrsStore.state.weatherStation.data.length
      ) {
        const stationData = vrsStore.state.weatherStation.data[stationIndex]
        if (stationData) {
          const datasets = getDatasets(stationData)
          if (!datasets) return null

          const chartDataset = {
            plotlyDataset: [],
            twoYAxes: false,
            windDataset: null,
            displayWind: false,
            rangeMin: null,
            rangeMax: null,
            rangeMode: 'normal',
            range2Min: null,
            range2Max: null,
            range2Mode: 'normal'
          }

          /**
           * The ground station widget
           * could have one or two dataset.
           *
           * If the first dataset is the wind direction,
           * we'll not try to add other dataset as the wind direction
           * is represented with a spider chart.
           */
          const selectedDatasets = Object.keys(itemStationDataset)
            .filter(k => itemStationDataset[k] === true)

          if (selectedDatasets.length === 0) return chartDataset

          const firstSelectedDataset = selectedDatasets[0]
          const isFirstSelectedDatasetWindDirection = firstSelectedDataset === 'datasetWindDirection'

          // Get datasetConf for each selected dataset
          // From unit, we sort data
          const datasetByUnit = selectedDatasets.reduce((acc, selectedDataset) => {
            const currentDataset = datasetsConfiguration[selectedDataset]
            if (acc[currentDataset.datasetScale]) {
              acc[currentDataset.datasetScale].push(currentDataset)
            } else {
              acc[currentDataset.datasetScale] = [currentDataset]
            }
            return acc
          }, {})

          const colorsAvailable = ['#b9a26c', '#03bde4', '#80ff7e', '#DD66D8', '#ffd402', '#ff2902', '#830202']

          // First axis
          const dataExist: Record<string, any>[] = Object.values(datasetByUnit)
          if (dataExist?.length > 0) {
            const firstDatasetConfiguration = dataExist[0]
            firstDatasetConfiguration.forEach((datasetConf, index) => {
              const firstDataset = datasets?.[datasetConf.datasetKey]
              datasetConf.datasetBindings.forEach(binding => {
                const { index: indexBindings, ...bindings } = binding
                if (isFirstSelectedDatasetWindDirection) {
                  chartDataset.plotlyDataset = firstDataset
                } else {
                  const currentDataset = firstDataset[indexBindings]
                  chartDataset.plotlyDataset.push({
                    ...firstDataset[indexBindings],
                    label: datasetConf.datasetKey,
                    yaxis: 'y',
                    marker: {
                      color: colorsAvailable[index]
                    }
                  })
                  if (!currentDataset.isAnnotation) {
                    setChartDatasetRanges(
                      chartDataset,
                      currentDataset,
                      datasetConf.datasetScale
                    )
                  }
                }
                Object.keys(bindings).forEach(b => { chartDataset[b] = bindings[b] })
              })
            })
          }

          // Second axis
          if (!isFirstSelectedDatasetWindDirection && (selectedDatasets.length > 1 && dataExist[1])) {
            const secondDatasetConfiguration = dataExist[1]

            const yaxis = 'y2'
            chartDataset.twoYAxes = true
            const rangeMinKey = 'range2Min'
            const rangeMaxKey = 'range2Max'
            const rangeModeKey = 'range2Mode'

            secondDatasetConfiguration.forEach((datasetConf, index) => {
              const secondDataset = datasets[datasetConf.datasetKey]
              datasetConf.datasetBindings.forEach(binding => {
                const currentDataset = secondDataset[binding.index]
                chartDataset.plotlyDataset.push({
                  ...currentDataset,
                  label: datasetConf.datasetKey,
                  yaxis,
                  marker: {
                    color: colorsAvailable[dataExist[0].length + index]
                  }
                })
                if (!currentDataset.isAnnotation) {
                  setChartDatasetRanges(
                    chartDataset,
                    currentDataset,
                    secondDatasetConfiguration.datasetScale,
                    rangeMinKey,
                    rangeMaxKey,
                    rangeModeKey
                  )
                }
              })
            })
          }
          return chartDataset
        }
        return null
      } else {
        return null
      }
    },
    getGroundStationChartMultiStationDataset (item: VueGridItem) {
      const stationSet: string[] = item.params.stationSet as string[]

      const serie = Object.keys(item.params.stationDataset).find(k => item.params.stationDataset[k]) || 'datasetAirTemp'

      const stationIndexes = stationSet.map(s => {
        switch (s) {
          case 'station1':
            return 0
          case 'station2':
            return 1
          case 'station3':
            return 2
          case 'station4':
            return 3
        }
      })

      if (vrsStore.state.weatherStation.data
      // stationIndex < vrsStore.state.weatherStation.data.length
      ) {
        const chartDataset = {
          plotlyDataset: [],
          twoYAxes: false,
          windDataset: null,
          displayWind: false,
          rangeMin: null,
          rangeMax: null,
          rangeMode: 'normal',
          range2Min: null,
          range2Max: null,
          range2Mode: 'normal'
        }

        stationIndexes.forEach(function (currentStationIndex) {
          /**
           * If the selected station doesn't exist, continue to the next one
           */
          if (currentStationIndex >= vrsStore.state.weatherStation.data.length) return
          const currentStationData = vrsStore.state.weatherStation.data[currentStationIndex]
          if (currentStationData) {
            const datasets = getDatasets(currentStationData)
            if (!datasets) return null

            /**
             * The ground multi station widget have only one serie,
             * but could have several (one to three) datasets.
             */
            const datasetConfiguration = datasetsConfiguration[serie]
            const currentDataset = datasets?.[datasetConfiguration.datasetKey]
            datasetConfiguration.datasetBindings.forEach(binding => {
              const { index, ...bindings } = binding
              const currentStationDataset = currentDataset[index]
              chartDataset.plotlyDataset.push({
                ...currentStationDataset,
                label: serie,
                name: `Station n°${currentStationIndex + 1}`,
                yaxis: 'y',
                marker: {
                  color: seriesColor[currentStationIndex]
                }
              })
              if (!currentStationDataset.isAnnotation) {
                setChartDatasetRanges(
                  chartDataset,
                  currentStationDataset,
                  datasetConfiguration.datasetScale
                )
              }
              Object.keys(bindings).forEach(b => {
                chartDataset[b] = bindings[b]
              })
            })
          }
        })

        return chartDataset
      } else {
        return null
      }
    },

    /**
     * Maximized action
     */
    toggleMaximized (item: VueGridItem) {
      /**
       * We init this actual state for retrocompatibility
       * Maximized widget state is a 3.2.x feature
       *
       * We store also the since params,
       * for notification purpose.
       * It allow us to compute how many forecast have been missed
       */
      const actualState = item.params.maximized ?? true
      this.$set(item.params, 'maximized', !actualState)

      /**
       * Store this widget in minimizedWidgets
       * only if type is forecast / rain / service info
       */
      if (
        actualState && // was the widget maximized ? so now he's minimized
        !item.params.syncedWithPlayer &&
        ['forecast-rain-information', 'service-information', 'forecast'].indexOf(item.type) >= 0
      ) {
        this.$set(this.minimizedWidgets, item.i, {
          since: DateTime.fromMillis(Date.now(), { setZone: true, zone: vrsStoreEvent.state.activeEvent.timezone })
            .toFormat('yyyyMMddHHmm00'),
          notifications: null
        })
      }
    },

    /**
     * Map methods
     */
    toggleLayer (layerPropName) {
      this.currentDashboard.settings[layerPropName] = !this.currentDashboard.settings[layerPropName]
    },

    onOsmOpacityChange (value: number): void {
      this.currentDashboard.settings.osmOpacity = value
    },
    onRadarOpacityChange (value: number): void {
      this.currentDashboard.settings.radarOpacity = value
    }

  },
  beforeDestroy () {
    this.stop()
  },
  watch: {
    currentTime: 'refreshData',
    selectedRangeLocal: {
      immediate: true,
      handler (newRange, oldRange): string[] {
        if (!newRange) return
        if (newRange[0] === oldRange?.[0] && newRange[1] === oldRange?.[1]) return
        if (!vrsStore.state.event.data) return
        if (!this.selectedRangeLocal) return
        fetchWeatherStationDataFilteredByDate(
          vrsStore.state.event.data.stations.map(s => s.id.toString()),
          convertDate(this.selectedRangeLocal[0], vrsStore.state.event.data.timezone),
          convertDate(this.selectedRangeLocal[1], vrsStore.state.event.data.timezone)
        )
      }
    },
    /**
     * Init the minimizedWidgets when currentDashboard is updated
     */
    currentDashboard: {
      immediate: true,
      handler (newDashboard): void {
        if (!newDashboard) return
        newDashboard.layout.forEach(widget => {
          if (
            !widget.params.maximized &&
            !widget.params.syncedWithPlayer &&
            ['forecast-rain-information', 'service-information', 'forecast'].indexOf(widget.type) >= 0
          ) {
            this.$set(this.minimizedWidgets, widget.i, {
              since: DateTime.fromMillis(Date.now(), { setZone: true, zone: vrsStoreEvent.state.activeEvent.timezone })
                .toFormat('yyyyMMddHHmm00'),
              notifications: null
            })
          }
        })
      }
    },
    'stateEvent.data': {
      immediate: true,
      async handler (newValue: WeatherEvent) {
        if (newValue) {
          await fetchRadarBbox(newValue.id)
          this.centerPreferenceMain = preferences.events?.[vrsStore.state.event.data.id]?.mapMainCenter
        }
      }
    },
    'stateForecast.data': {
      deep: true,
      handler: 'refreshNotifications'
    },
    'widgetFromDashboard.type': {
      deep: true,
      handler (newValue: string, oldValue: string) {
        const referenceToWidgetFromDashboard = this.widgetFromDashboard
        if (!referenceToWidgetFromDashboard) return
        // if not current widget, we can't edit params / styles
        if (!this.currentWidgetInEdition) return
        // To prevent multiple trigger and
        // to apply only when widget type has already been specified (i.e. not to the creation)
        if (oldValue) {
          let newParams = {}
          let newStyles = {}
          switch (newValue) {
            case 'player':
              newParams = {
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: false,
                opacity: 100,
                size: 'base'
              }
              break
            case 'event-overview':
              newParams = {
                seriesOverview: ['avgAirTemp', 'avgTrackTemp']
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true,
                opacity: 100,
                size: 'base'
              }
              break
            case 'compare-sessions':
              newParams = {
                seriesCompare: ['avgAirTemp', 'avgTrackTemp'],
                ...selectionSessions({
                  defaultSessionA: null,
                  defaultSessionB: null,
                  defaultStationA: 'all',
                  defaultStationB: 'all'
                }, this.stateEvent.sessionsWithStat)
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true,
                opacity: 100,
                size: 'base'
              }
              break
            case 'weather-radar-map':
              newParams = {
                osmOpacity: 1,
                radarOpacity: 1,
                displayLayersWeatherStations: true,
                displayLayersWindArrows: true,
                displayLayersRainpath: true,
                syncedWithPlayer: true
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: false,
                opacity: 100,
                size: 'base'
              }
              break
            case 'weather-station-listing':
              newParams = {
                currentStation: 0,
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true,
                opacity: 100,
                size: 'base'
              }
              break
            case 'weather-station-listing-multi-station':
              newParams = {
                stationSet: ['station1'],
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true,
                opacity: 100,
                size: 'base'
              }
              break
            case 'ground-station-chart':
              newParams = {
                stationIndex: 0,
                stationDataset: {
                  datasetAirTemp: true,
                  datasetTrackTemp: true,
                  datasetWindAvg: false,
                  datasetWindGust: false,
                  datasetHumidity: false,
                  datasetPressure: false,
                  datasetRainIntensity: false,
                  datasetWindDirection: false,
                  datasetWBGT: false,
                  datasetHeatIndex: false,
                  datasetLightIntensity: false
                },
                displaySession: true,
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true,
                opacity: 100,
                size: 'base'
              }
              break
            case 'ground-station-chart-multi-station':
              newParams = {
                stationSet: ['station1', 'station2', 'station3', 'station4'],
                stationDataset: {
                  datasetAirTemp: true,
                  datasetTrackTemp: false,
                  datasetWindAvg: false,
                  datasetWindGust: false,
                  datasetHumidity: false,
                  datasetPressure: false,
                  datasetRainIntensity: false,
                  datasetLightIntensity: false
                },
                displaySession: true,
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true,
                opacity: 100,
                size: 'base'
              }
              break
            case 'forecast':
              newParams = {
                temperatureOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_TEMPERATURE_AIR,
                chanceOfRainOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_RAIN_CHANCE || [],
                windOverLimit: this.stateConfig.data.LIMIT_FORECAST_BYDAY_WIND || [],
                gustsOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_WIND_GUSTS || [],
                humidityOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_HUMIDITY,
                pressureOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_PRESSURE,
                trackMinOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MIN,
                trackMaxOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MAX
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true,
                opacity: 100,
                size: 'base'
              }
              break
            default:
              newParams = {
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true,
                opacity: 100,
                size: 'base'
              }
          }

          this.$set(referenceToWidgetFromDashboard, 'params', newParams)
          this.$set(referenceToWidgetFromDashboard, 'styles', newStyles)

          const typeWidget = typesWidgets.find(({ value }) => value === referenceToWidgetFromDashboard.type)
          if (typeWidget.value !== 'unknown') {
            referenceToWidgetFromDashboard.title = typeWidget.label
          } else {
            referenceToWidgetFromDashboard.title = 'Unknown widget'
          }
        }
      }
    }
  }
}
